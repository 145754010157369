/* global $ */
import '../../css/app/components/home.scss';
class Home
{
    constructor () 
    {
        $('.video-play').click(() => {
            this.playVideo();
        });
        console.log('Home initialised');
    }
    playVideo() {
        var video = $('#videoImagePlaceholder').data('video');
        $('.video-block-pos').replaceWith(video);
        $('.video-block').addClass('video-iframe-container');
    }
}

new Home;